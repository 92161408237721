import $ from "jquery";

import {elementReady} from "../ReadyListener";
import {unescapeAndOpenModalWithId} from "../components/component.modal";


elementReady(async  (root) => {
    const slaChooser = root.findWithRoot('.sla-chooser');

    slaChooser.on('click', (ev)=>{
        ev.preventDefault();
        ev.stopPropagation();

        const packagePrice = $(ev.target).closest("[data-package-price]").data("package-price");
        const slaPrice = $(ev.target).closest("[data-sla-price]").data("sla-price");

        unescapeAndOpenModalWithId("saas-sla-order");

        $('body').find("[name=package]").val(`${packagePrice} + ${slaPrice}`);
    });
    
})
